<template>
	<b-container fluid v-can="'purchase.request.create'">
		<b-row>
			<b-col md="12 m-n">
				<iq-card>
					<template v-slot:headerTitle>
						<div class="d-flex align-items-center">
							<b-breadcrumb
								:items="items"
								class="bg-transparent mb-0 mt-1"
							></b-breadcrumb>
						</div>
					</template>
				</iq-card>
			</b-col>
			<b-col lg="12">
				<iq-card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Nueva Solicitud de Compra</h4>
					</template>
					<template v-slot:body>
						
					</template>
				</iq-card>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="12">
			<!--<iq-card v-if="form.products.length">
					<template v-slot:body>
						<!-- Tabla de productos 

						<iq-card>
							<template v-slot:headerTitle>
								<h4 class="card-title">Productos</h4>
							</template>

							<template v-slot:body>
								<b-row>
									<b-col md="12" class="table-responsive">
										<b-table hover :items="form.products" :fields="columns">
											<template v-slot:cell(name)="data">
												<span>{{ data.item.name }}</span>
											</template>
											<template v-slot:cell(quantity)="data">
												<span>{{ data.item.quantity }}</span>
											</template>
											<template v-slot:cell(option)="data">
												<b-button
													variant=" iq-bg-danger"
													size="sm"
													v-confirm="{
														ok: (dialog) => remove(data.item.id),
													}"
													><i class="ri-delete-bin-line m-0"></i
												></b-button>
											</template>
										</b-table>
									</b-col>
								</b-row>
							</template>
						</iq-card>
						<hr />
						<b-button variant="primary" @click="onSubmit">Guardar</b-button>
					</template>
				</iq-card> -->
			</b-col>
		</b-row>
		 <b-modal size="lg" id="bv-modal-product" hide-footer>
				<template #modal-title> Agregar nuevo producto</template>
				<b-row>
						<register-product origin="1" v-on:hide="hide"/>
				</b-row>
			</b-modal>
	</b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RegisterProduct from "@/views/Admin/Products/forms/createOrUpdate.vue";
export default {
	name: 'Register',
	components: {
		RegisterProduct
	},
	async mounted () {
		this.$store.commit('run')
		core.index()
		const areas = await this.$store.dispatch('area/listArea')
		this.areas = areas.map((item) => ({ id: item.id, label: item.name }))
		this.$store.commit('stop')
	},
	data () {
		return {
			columns: [
				{ label: "Nombre", key: "name", class: "text-center" },
				{ label: "Cantidad", key: "quantity", class: "text-center" },
				{ label: "Opciones", key: "option", class: "text-center" }
			],
			form: {
				area_id: '',
				//quantity: '',
				description: '',
				//products: []
			},
			data: null,
			areas: [],
			productsSelect: [],
			product: null,
			ac: 0,
			items: [
				{
					html: 'Inicio',
					to: '/'
				},
				{
					html: 'Solicitudes de Compras',
					to: '/purchase_requests/list'
				},
				{
					text: 'Nueva Solicitud de Compra',
					active: true
				}
			]
		}
	},
	computed: {
		productsOptions () {
			if (this.productsSelect.length) {
				return this.productsSelect.map(({ id: value, name: label }) => ({
					value,
					label
				}))
			}
			return []
		}
	},
	methods: {
			hide(res){
			this.product = { value: res.id, label: res.name }
			this.$bvModal.hide("bv-modal-product");
		},
		async saveProduct(){ 
			console.log(this.data)
			const payload = {
				name: this.data,
				type: 1
			}
			try {
				this.$store.commit("run");
				const res = await this.$store.dispatch("products/storeProducts", payload);
        		this.product = { value: res.id, label: res.name }
				this.$store.commit("stop");
				core.showSnackbar("success", "Producto Agregado Correctamente");
				this.data = null
			} catch (error) {
				this.data = null
				console.log(error)
				this.$store.commit("stop");
				const errors = error.response.data.errors;
				for (const key in errors) {
					core.showSnackbar("error", errors[key][0]);
					return false;
				}
			}

		},
		addProduct () {
			if (!this.description) {
				core.showSnackbar('error', 'Por favor ingrese una descripción')
				return
			}
			if (!this.quantity) {
				core.showSnackbar('error', 'Agrega la cantidad del producto')
				return
			}
			this.form.products.push({
				product_id: this.product.value,
				name: this.product.label,
				quantity: this.quantity
			})
			this.quantity = ''
			this.product = ''
			this.description = ''
		},
		async onSubmit () {
			try {
				let payload = this.form;
				this.$store.commit("run");
				const resp = await this.$store.dispatch(
					"purchase/storePurchaseRequest",
					payload
				)
				this.$store.commit('stop')
				core.showSnackbar(
					'success',
					'Solicitud de Compra agregada Correctamente'
				)
				this.$router.replace(`/purchase_requests/show/${resp.id}`)
			} catch (error) {
				this.$store.commit('stop')
				const errors = error.response.data.errors
				for (const key in errors) {
					core.showSnackbar('error', errors[key][0])
					return false
				}
			}
		},
		// Funcion para buscar productos en base a la busqueda
		async fetchProducts (search, loading) {
      this.ac = 0
			if (search && search.length > 2) {
				loading(true)
				const products = await this.$store.dispatch('products/listProducts', {
					search
				})
				if(products.length === 0){
					this.ac = 1
					this.data = search
				}else{
					this.ac = 0
					this.data = null
				}
				loading(false)
				this.productsSelect = products
			} else {
				this.productsSelect = []
			}
		},
		remove (item) {
			const index = this.form.products.indexOf(item)
			this.form.products.splice(index, 1)
			core.showSnackbar('success', 'Producto eliminado correctamente')
		}
	}
}
</script>
